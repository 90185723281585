﻿.features-accordion-group {
    max-width: 500px;
    width: 100%;
    border-top: 1px solid rgba(148, 148, 148, 0.3);
}

.features-accordion .slide-up-position {
    display: none;

    @media (min-width: $screen-md) {
        display: block;
    }
}

.features-accordion-bg {
    opacity: 0;
    transition: opacity var(--transition-timing-md) var(--ease-in-out);

    &.active {
        opacity: 1;
    }
}

.features-accordion .slide-up-content {
    @media (max-width: $screen-sm-max) {
        padding-top: var(--stacked-component-lg);
        padding-bottom: var(--stacked-component-lg);
    }
}

.accordion-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.accordion-header {
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: color var(--transition-timing-sm) var(--ease-in-out);

    .features-accordion-group & {
        color: rgba(255, 255, 255, 0.8);
    }

    &:hover {
        color: var(--text-primary);
    }

    .active > & {
        color: var(--text-primary);
    }

    .heading-4 {
        color: inherit;
    }
}

.accordion-arrow {
    width: 20px;
    height: 20px;
    transform: scaleY(-1);
    transition: transform var(--transition-timing-sm) var(--ease-in-out);

    .accordion-item.active & {
        transform: scaleY(1);
    }
}

.accordion-content {
    // padding-bottom: 2.5rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--transition-timing-md) var(--ease-in-out);

    .active > & {
        max-height: 5000px; /* Adjust this value based on your content height */
    }
}

// need to put the padding here so we can collapse properly
.accordion-content-inner {
    padding-bottom: 2.5rem;

    p {
        max-width: 100%;
    }
}

.accordion-mobile-image {
    width: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 1.5rem;

    @media (min-width: $screen-md) {
        display: none;
    }
}
