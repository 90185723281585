

/**umb_name:Tungsten*/
.rte-font-tungsten {
	font-family: 'Tungsten';
}

/**umb_name:Forza*/
.rte-font-forza {
	font-family:'Forza';
}
