﻿.blurb {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .subheading {
        margin: 0;
    }

    p {
        max-width: 40ch;
        margin: 0;
    }
}

.blurb-heading {
    max-width: 10ch;
    margin: 0;
}
