﻿/* Menu button and Animation
------------------------------------------*/

.menu-button {
    --menu-button-width: 44px;
    --menu-dash-width: 26px;
    --menu-dash-width-sm: 20px;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    font: inherit;
    color: inherit;
    width: var(--menu-button-width);
    height: var(--menu-button-width);
    position: relative;
    z-index: 20;
    margin-left: -8px;
    cursor: pointer;
}

//NOTE: different layers inside the button
.hamburger,
.close {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    span {
        height: 2px;
        width: var(--menu-dash-width);
        display: block;
        background-color: var(--text-primary);
        transition: all var(--transition-timing-sm) var(--ease-out);
        border-radius: var(--radius-sm);

        .show-nav &,
        .fixed & {
            background-color: var(--color-neutral-800);
        }
    }
}

// the default state of the menu button is hamburger
.hamburger {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 8px;

    span {
        transform-origin: 0;
        width: var(--menu-dash-width-sm);
        // timing function for when the menu button overs for all the hamburger spans.
        .menu-button:hover & {
            animation: var(--transition-timing-md) var(--ease-in-out) 0ms 1 menu-hover-last;
        }

        &:nth-child(1) {
            width: var(--menu-dash-width);
            transition-delay: calc(var(--transition-timing-sm) + 150ms);
            // the first hover animation is different than the last 2
            .menu-button:hover & {
                animation-name: menu-hover-first;
            }
        }

        &:nth-child(2) {
            transition-delay: calc(var(--transition-timing-sm) + 75ms);
        }

        &:nth-child(3) {
            transition-delay: var(--transition-timing-sm);
            // add a slight animation delay for hte last span for the menu button hover
            .menu-button:hover & {
                animation-delay: 100ms;
            }
        }
    }
}

// transitions for closing the hamburger menu
.show-nav .hamburger span {
    transform: scale(0);
    transition-delay: 0ms;

    &:nth-child(2) {
        transition-delay: 75ms;
    }

    &:nth-child(3) {
        transition-delay: 150ms;
    }
}

// transitions and positinging for the close X button
.close span {
    position: absolute;
    top: 50%;
    left: calc((var(--menu-button-width) - var(--menu-dash-width)) / 2);
    transition-delay: 0ms;

    &:first-child {
        transform: rotate(45deg) scale(0);
    }

    &:last-child {
        transform: rotate(-45deg) scale(0);
    }
}

//transitions for restoring the hamburger menu from the close X button
.show-nav .close span {
    transition-delay: 0ms;
    transition-delay: var(--transition-timing-sm);

    &:first-child {
        transform: rotate(-45deg) scale(1);
    }

    &:last-child {
        transform: rotate(45deg) scale(1);
    }
}

/* Keyframe animations for the menu hovers
------------------------------------------*/

@keyframes menu-hover-first {
    0% {
        width: var(--menu-dash-width);
    }

    50% {
        width: var(--menu-dash-width-sm);
    }

    100% {
        width: var(--menu-dash-width);
    }
}

@keyframes menu-hover-last {
    0% {
        width: var(--menu-dash-width-sm);
    }

    50% {
        width: var(--menu-dash-width);
    }

    100% {
        width: var(--menu-dash-width-sm);
    }
}
