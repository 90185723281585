@font-face {
    font-family: 'Acumin Pro';
    src: url('./AcuminPro-Light.otf') format('opentype');
    /* font-weight: normal;
    font-style: normal; */
}

/* @font-face {
    font-family: 'Acumin Pro';
    src: url('./AcuminPro-Light.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('./AcuminPro-Light.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('./AcuminPro-Light.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
} */
