﻿:root, .theme-light {
    /* Theming colors
  ----------------------------------------------------------*/
    --text-primary: var(--color-neutral-800);
    --text-secondary: var(--color-neutral-500);
    --text-link: var(--text-primary);
    --text-link-hover: var(--color-neutral-500);
    --text-disabled: var(--color-neutral-400);
    --background-primary: var(--color-neutral-100);
    --background-secondary: var(--color-neutral-200);
    --border-color: var(--color-neutral-400);
    --button-background-primary: var(--color-primary-200);
    --button-background-primary-hover: var(--color-primary-300);
    --button-background-secondary: var(--color-neutral-800);
    --button-background-secondary-hover: var(--color-neutral-900);
    --button-background-disabled: var(--color-neutral-200);
    --button-text-primary: var(--color-neutral-white);
    --button-text-secondary: var(--color-neutral-white);
    --button-text-tertiary: var(--color-neutral-800);
    --button-text-disabled: var(--color-neutral-400);
    --button-text-tertiary-disabled: var(--color-neutral-400);
    --button-border-tertiary: var(--color-neutral-800);
    --button-border-tertiary-hover: var(--color-neutral-800);
    --button-text-chicklet: #222;
    --button-background-chicklet: rgba(0, 0, 0, 0.05);
    --button-text-chicklet-hover: #FFF;
    --button-background-chicklet-active: #222;
    --input-label: var(--color-neutral-700);
    --input-placeholder: var(--color-neutral-500);
    --input-value: var(--text-primary);
    --input-value-disabled: var(--text-disabled);
    --input-icon-color: var(--color-neutral-800);
    --input-success: var(--color-success-300);
    --input-border-default: var(--border-color);
    --input-border-active: var(--color-neutral-900);
    --input-border-disabled: var(--color-neutral-300);
    --input-fill-default: var(--background-primary);
    --input-fill-active: var( --color-neutral-900 ); // used on checkboxes and radios
    --input-fill-disabled: var(--color-neutral-200);
    --tooltip-background: var(--color-neutral-800);
    --tooltip-text: var(--color-neutral-white);
    --overflow-scrollbar-thumb-color:var(--color-neutral-800);
    --overflow-scrollbar-track-color:rgba(194, 194, 194, 0.7);
}

.theme-light,
.theme-dark,
.theme-gray {
    background: var(--background-primary);
    color: var(--text-primary);
}

.theme-dark {
    --text-primary: var(--color-neutral-white);
    --text-secondary: var(--color-neutral-400);
    --text-link: var(--text-primary);
    --text-link-hover: var(--text-primary);
    --text-disabled: var(--color-neutral-450);
    --background-primary: var(--color-neutral-900);
    --background-secondary: var(--color-neutral-700);
    --border-color: var(--color-neutral-100);
    --button-background-secondary: var(--color-neutral-white);
    --button-background-secondary-hover: var(--color-neutral-200);
    --button-background-disabled: var(--color-neutral-800);
    --button-text-secondary: var(--color-neutral-800);
    --button-text-tertiary: var(--color-neutral-white);
    --button-text-disabled: var(--color-neutral-500);
    --button-text-tertiary-disabled: var(--color-neutral-450);
    --button-border-tertiary: var(--color-neutral-100);
    --button-border-tertiary-hover: var(--color-neutral-white);
    --button-text-chicklet: #FFF;
    --button-background-chicklet: rgba(255, 255, 255, 0.10);
    --button-text-chicklet-hover: #222;
    --button-background-chicklet-active: #FFF;
    --input-label: var(--color-neutral-100);
    --input-placeholder: var(--color-neutral-400);
    --input-icon-color: var(--color-neutral-white);
    --input-success: var(--color-success-200);
    --input-border-active: var(--color-neutral-white);
    --input-border-disabled: var(--color-neutral-600);
    --input-fill-active: var( --color-neutral-white ); // used on checkboxes and radios
    --input-fill-disabled: var(--color-neutral-700);
    --tooltip-background: var(--color-neutral-white);
    --tooltip-text: var(--color-neutral-800);
    --overflow-scrollbar-thumb-color:var(--color-neutral-300);
    --overflow-scrollbar-track-color:rgba(82, 82, 79, 0.70);
    background: var(--background-primary);
}

.theme-gray {
    // mostly the same as primary
    --text-disabled: var(--color-neutral-500);
    --background-primary: var(--color-neutral-100);
    --background-secondary: var(--color-neutral-white);
    --border-color: var(--color-neutral-600);
    --button-background-disabled: var(--color-neutral-300);
    --input-border-disabled: var(--color-neutral-400);
    --input-fill-disabled: var(--color-neutral-300);
}

.gradient-light {
    background-image: linear-gradient(0deg, #fff 0%, var(--color-neutral-200) 100%);
    background-attachment: fixed;
    background-size: 100% 100%;
    background-position: 0 0;
}


  img[type="image/svg"]{
    .theme-light &{
      filter: invert(0);
    }
    .theme-dark &{
      filter: invert(1);
    }
  }

