﻿.family-tab-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  height: 0;
  z-index: 20;
}

.family-tab {
  padding: 10px 16px;
  @include subheading;
  color: var(--color-neutral-white);
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  top: 400px;
  font-size: .75rem;
  border-radius: 0 0 var(--radius-md) var(--radius-md);


  @media (min-width: $screen-xl) {
    padding: 12px 24px;
    font-size: 1rem;
  }


  .xt-series & {
    background-color: var(--color-secondary-gray, #757B79);
  }

  .x-series & {
    background-color: var(--color-secondary-red, #823C38)
  }

  .nxt-series & {
    background: var(--color-secondary-dark-red, #453131);
  }

  .xstar-series & {
    background-color: var(--color-secondary-dark-gray, #50535E);
  }

  .prostar-series & {
    background-color: var(--color-secondary-beige, #B4AFA3);
  }
}