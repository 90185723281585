﻿.overflow-gallery {
    display: flex;
    gap: var(--grid-horizontal-spacing);
    overflow-x: auto;
    opacity: 0;
    transform: translateX(10%);
    transition: all var(--transition-timing-xxl) var(--ease-out);

    &.slide-in {
        opacity: 1;
        transform: translateX(0);
    }
}

.overflow-item {
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    flex-shrink: 0;

    @media (min-width: $screen-md) {
        width: 400px;
    }

    p {
        margin: 0;
    }


    img {
        max-width: 100%;
        margin-bottom: 1rem;
    }
}
