﻿
/*.hero-image-row-content {
    background: rgba(27,23,22);

    .theme-dark {
        background: rgba(27,23,22);
    }
}*/


.hero-image-row-intro {
    padding-top: var(--masthead-height-combined);
}

.hero-image-bg {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

.page-heading {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--stacked-component-lg);
    padding-bottom: var(--stacked-component-lg);

    p {
        max-width: var(--container-width-md);
    }
}
