﻿:root {
    --masthead-height: 56px;
    --sticky-masthead-height: 119px;
    --masthead-border-color: hsla(0, 0%, 58%, 0.3);

    @media (min-width: $screen-md) {
        --masthead-height: 80px;
        --sticky-masthead-height: 70px;
    }

    --masthead-height-combined: calc( var(--masthead-height) + var(--sticky-masthead-height) );
}

.masthead-wrapper {
    color: var(--text-primary);
    position: absolute;
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    transition: background-color var(--transition-timing-sm) linear 0s;

    &.fixed {
        color: var(--color-neutral-800);
        position: fixed;
        transform: translateY( calc(var(--masthead-height) * -1) ); // nav height desktop
        background-color: var(--color-neutral-white);
    }

    &.slide-in {
        transform: translateY(0);
        transition: background-color var(--transition-timing-md) linear 0s, transform var(--transition-timing-sm) var(--ease-out) 300ms;
    }

    &.slide-out {
        transform: translateY( calc(var(--masthead-height) * -1) ); // nav height desktop
        transition: background-color var(--transition-timing-md) linear 0s, transform var(--transition-timing-sm) var(--ease-out);
    }
}

.masthead {
    padding: 6px var(--container-padding);
    position: relative;

    @media (max-width: 799px) {
        z-index: 20;
    }

    @media (min-width: $screen-md) {
        display: flex;
        justify-content: space-between;
        padding-top: 1.125rem;
        padding-bottom: 1.125rem;
    }
    // NOTE: This sets the border on the bottom of the nav bar that matches the container width.
    &::after {
        content: "";
        position: absolute;
        height: 1px;
        left: var(--container-padding);
        right: var(--container-padding);
        bottom: 0;
        background-color: var(--masthead-border-color);
    }
}

// Note: need to make the background white so boats can scroll under the masthead.
.show-nav .masthead {
    @media (max-width: $screen-sm-max) {
        background-color: var(--color-neutral-white);
        transition-delay: var(--transition-timing-md);
    }
}

.masthead-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-md) {
        gap: 1.5rem;
    }
}

.masthead-logo {
    width: 168px;
    aspect-ratio: 232/25;
    position: relative;
    z-index: 20;
    fill: currentColor;
    transition: fill var(--transition-timing-md) var(--ease-out);

    .show-nav & {
        fill: var(--color-neutral-800);
    }

    @media (min-width: $screen-md) {
        width: 187px;
    }
}

.search-mobile {
    position: relative;
    z-index: 20;
    margin-left: 10px;

    @media (min-width: $screen-md) {
        display: none;
    }
}

.utility-nav {
    display: none;

    @media (min-width: $screen-md) {
        display: flex;
        gap: 1.5rem;
        z-index: 10;
        align-items: center;
    }
}

.utility-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.masthead-sticky {
    padding: 1rem 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    gap: .5rem;
    flex-wrap: wrap;

    @media (min-width: $screen-md) {
        padding: 1rem var(--container-padding);
        flex-wrap: nowrap;
        gap: 3rem;
    }
}

.sticky-heading {
    color: inherit;
    white-space: nowrap;
    padding-left: var(--container-padding);

    @media (min-width: $screen-md) {
        order: 1;
        padding-left: 0;
    }
}

.sticky-navigation {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    // border-top: 1px solid var(--masthead-border-color);
    // margin-left: calc(var(--container-padding) * -1);
    // margin-right: calc(var(--container-padding) * -1);
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    white-space: nowrap;
    overflow-x: auto;
    position: relative;
    // &:before {
    //   content: '';
    //   // width: auto;
    //   border-top: 1px solid var(--masthead-border-color);
    //   position: absolute;
    //   left: var(--container-padding);
    //   right: var(--container-padding);
    //   top: 0;
    // }

    @media (min-width: $screen-md) {
        margin: 0;
        padding: 0;
        border-top: none;
        order: 2;
        width: auto;
        flex-grow: 2;
    }
}

.sticky-tab-link {
    display: block;
    padding: 0.5em 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 150%;
    color: inherit;
    border-bottom: 3px solid transparent;
    transition: border-bottom-color var(--transition-timing-md) var(--ease-out);
    cursor: pointer;

    &:hover {
        text-decoration: none;
        border-bottom-color: var(--text-primary);
    }

    &.active {
        border-bottom-color: var(--color-primary-200);
    }

    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
    }
}

.sticky-buttons {
    display: flex;
    gap: 12px;
    padding-right: var(--container-padding);
    overflow-x:auto;

    @media (min-width: $screen-md) {
        order: 3;
        padding-right: 0;
    }

    button {
        white-space: nowrap;
        padding: 10px 18px;

        @media (max-width: $screen-sm-max) {
            font-size: 0.875rem;
            padding: 8px 12px;
        }
    }

    .tertiary {
        .fixed & {
            border-color: var(--color-neutral-800);
            color: var(--color-neutral-800);
        }
    }
}
