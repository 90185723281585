﻿.feature-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 280px;
    min-width: 280px;

    img {
        border-radius: var(--radius-md);
    }

    .heading-4 {
        margin: 0;
    }

    p {
        margin: 0;
    }
}
