

/**umb_name:Uppercase*/
.rte-uppercase {
	text-transform: uppercase;
}

/**umb_name:Lowercase*/
.rte-lowercase {
	text-transform: lowercase;
}

/**umb_name:Capitalize*/
.rte-capitalize {
	text-transform: capitalize;
}
