﻿.slide-up-section {
  position: relative;
  min-height: 100vh;
}

.slide-up-position {
  position: sticky;
  height: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  nav:has(.masthead-wrapper #sticky-nav-container)+main .slide-up-section & {
    top: var(--sticky-masthead-height)
  }
}

.slide-up-image {
  position: absolute;
  height: 100vh;
  width: 100%;

  nav:has(.masthead-wrapper #sticky-nav-container)+main .slide-up-section & {
    height: calc(100vh - var(--sticky-masthead-height));
  }

  img,
  video {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}


.slide-up-content {
  position: relative;
  padding-top: 70vh;
  padding-bottom: 30vh;
  z-index: 6;
  padding-left: var(--container-padding-lg);
  padding-right: var(--container-padding-lg);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;

  // note this all could/should be replaced with blurb.scss
  .subheading {
    margin: 0;
  }

  .display-2 {
    max-width: 10ch;
    margin: 0;
  }

  p {
    max-width: 40ch;
    margin: 0;
  }
}