﻿.slide-up-feature-gallery {
    background:var(--background-primary);
    background: var(--umb-background-image ,url(../../assets/images/dab-bg.jpg)) no-repeat fixed center center;
    // background-image:var(--umb-background-image);
    background-size: cover;
    overflow-x: hidden;
}

.slide-up-feature-inner {
    background-color: rgba(0,0,0,.2);
    padding: 50vh var(--container-padding-lg) var(--stacked-component-lg) var(--container-padding-lg);
    display: flex;
    flex-direction: column;
    gap: var(--stacked-component-lg);

    .overflow-gallery {
        margin-right: calc(var(--container-padding-lg) * -1);
        margin-left: calc(var(--container-padding-lg) * -1);
        padding-left: var(--container-padding-lg);
        padding-right: var(--container-padding-lg);
        padding-bottom: 3.5rem;
    }
}

.slide-up-feature-intro {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;

    .subheading {
        margin: 0;
    }

    .display-2,
    .display-3 {
        max-width: 14ch;
        margin: 0;
    }

    p {
        max-width: 40ch;
        margin: 0;
    }
}


.feature-image-wrapper {
    aspect-ratio: 280/370;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--radius-md);
    }
}
