.shoppingListRow {
    &.theme-light &-wrapper {
        background-color: #0000000d;
    }

    &.theme-dark &-wrapper {
/*        background: var(--background-primary);
*/        background: var(--color-semi-transparent-fills-transparent-white, rgba(255, 255, 255, 0.10));

        img[type="image/svg"] {
            filter: invert(100%) sepia(2%) saturate(10%) hue-rotate(56deg) brightness(103%) contrast(103%);
        }
    }
}
