:root {
  --overflow-scrollbar-thumb-color: #222222;
  --overflow-scrollbar-track-color: rgba(194, 194, 194, 0.7);
}

.theme-dark {
  --overflow-scrollbar-thumb-color: #E0DFDC;
  --overflow-scrollbar-track-color: rgba(82, 82, 79, 0.70);
}

$scrollbar-height: 4px;
$scrollbar-track-color: var(--overflow-scrollbar-track-color);
$scrollbar-handle-color: var(--overflow-scrollbar-thumb-color);
// $scrollbar-handle-color-mobile: #1f225d;
// $scrollbar-track-color-mobile: #d2d7ec;
$scrollbar-radius: 2px;
$container-gap: 1.5rem;
$button-width: 3.5rem;


.scroll-button {
  appearance: none;
  border: none;
  padding: 1rem;
  max-height: $button-width;
  max-width: $button-width;
  border-radius: var(--radius-md);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: calc(50% - 26px); // 26 is ½ height of the button
  transform: translate(-50%, -50%);
  right: calc(100% - #{$button-width});
  background-color: var(--background-primary);
  color: var(--text-primary);
  box-shadow: 0px 2px 4px 0px rgba(32, 37, 50, 0.03), 0px 2px 8px 0px rgba(32, 37, 50, 0.08);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;


  @media (min-width: 1400px) {
    right: calc(100% - #{$container-gap});
  }



  &.next {
    left: calc(100% - #{$button-width});
    right: auto;
    transform: translate(50%, -50%);

    @media (min-width: 1400px) {
      left: calc(100% - #{$container-gap});
    }
  }

  &.active {
    visibility: visible;
    transform: translate(0, -50%);
    pointer-events: auto;
    opacity: 1;
  }
}

.overflow-scroll-container {
    --overflow-scrollbar-thumb-color: #222222;
    --overflow-scrollbar-track-color: rgba(194, 194, 194, 0.7);  
  .theme-dark & {
    --overflow-scrollbar-thumb-color: #E0DFDC;
    --overflow-scrollbar-track-color: rgba(82, 82, 79, 0.70);
  }
  position: relative;
  overflow-x: auto;
  margin-left: calc(var(--container-padding) * -1);
  margin-right: calc(var(--container-padding) * -1);
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scroll-padding: var(--container-padding);
  display: flex;
  padding-block: 2.5rem;

  @media (min-width: $screen-md) {
    padding-block: 3.5rem;
  }

  // padding-top: var(--stacked-component-md);
  // padding-bottom: var(--stacked-component-md);
  gap: $container-gap;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);

  // TODO add support for firefox
  &::-webkit-scrollbar {
    height: $scrollbar-height;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
    margin: 0 var(--container-padding);
    border-radius: $scrollbar-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-handle-color;
    border-radius: $scrollbar-radius;
  }
}

.handle,
.track {
  position: absolute;
  bottom: 0;
  left: 0;
  height: $scrollbar-height;
  border-radius: $scrollbar-radius;
}

.handle {
  background: $scrollbar-handle-color;
  width: 10px;
  z-index: 11;
}

.track {
  background: $scrollbar-track-color;
  width: 100%;
  z-index: 10;
}

// a.button.chicklet-tertiary {
//   --button-padding-inline: 0.75rem;
//   --button-padding-block: 0.5rem;
//   border-radius: 0;


//   .theme-dark & {
//     --button-padding-inline: 0.5rem;
//     background-color: var(--color-neutral-800);
//     border-color: var(--color-neutral-800);
//     border-radius: var(--radius-md);
//   }
// }