@import './rte.fontFamily.css';
/* @import './rte.fontType.css'; */
@import './rte.css';
@import './rte.fontWeight.css';
@import './rte.textTransform.css';

@media screen and (max-width:768px) {
  .desktop-break {
    display: none;
  }

  .mobile-break {
    display: block;
  }
}

@media screen and (min-width:769px) {
  .desktop-break {
    display: block;
  }

  .mobile-break {
    display: none;
  }
}