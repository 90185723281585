﻿/* Content
------------------------------------------*/
.hero-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: relative;
    max-height: 720px;
    z-index: 1;
}


.stacked-component {
    position: relative;
    z-index: 5;
    padding-top: var(--stacked-component-md);
    padding-bottom: var(--stacked-component-md);

    &-sm {
        padding-top: var(--stacked-component-sm);
        padding-bottom: var(--stacked-component-sm);
    }

    &-lg {
        padding-top: var(--stacked-component-lg);
        padding-bottom: var(--stacked-component-lg);
    }
}

.page-content {
    position: relative;
    z-index: 6;
    min-height: 100vh;

    .masthead-wrapper + & {
        padding-top: var(--masthead-height-combined);
    }

    .home & {
        background: var(--color-neutral-white);
        padding-top: var(--stacked-component-lg);
        padding-bottom: var(--stacked-component-lg);
        scroll-snap-align: start;
    }
}


.type-sample {
    border-top: 1px solid var(--color-neutral-300);
    padding: 2rem 0;

    p {
        max-width: 60ch;
    }

    .subheading-md {
        margin-top: 1rem;
    }
}
