@tailwind base;
@tailwind components;
@tailwind utilities;

/* SalesForce Form Style Start*/
@layer components {
  .email-signup-bg {
    background: linear-gradient(180deg, #222 0%, #191919 100%);
  }

  .all-boats-bg {
    background: linear-gradient(180deg,#E9E8E6 0%,#F4F4F3 100%);
  }

  .footer-email-signup {
    all: unset;
    border-bottom: 1px solid;
    @apply w-full border-neutral-100 bg-inherit py-3.5 placeholder:font-Forza placeholder:text-xs/none placeholder:tracking-[.2em] placeholder:text-white outline-none focus:border-0 focus:border-b focus:border-current focus:ring-0
  }
}

@layer components {
  .sales-force-form .form__group {
    @apply relative pt-4 px-0 pb-0 mt-3;
  }

  .sales-force-form .form__field {
    @apply w-full border-0 border-b border-solid outline-0 font-Forza text-base font-normal not-italic tracking-[0.7px] px-0 py-2 bg-transparent [transition:border-color_0.2s] focus:outline-none focus:ring-transparent placeholder-transparent;
  }

  .sales-force-form .form__select {
    @apply w-full border-0 border-b border-solid font-Forza text-base font-normal not-italic tracking-[0.7px] outline-0 py-2.5 px-0 bg-transparent appearance-none dark:text-white dark:placeholder-white dark:border-solid focus:outline-none focus:ring-0 focus:border-gray-200;
  }

  .theme-dark .sales-force-form .form__select {
    @apply w-full border-0 border-b border-solid font-Forza text-base font-normal not-italic tracking-[0.7px] outline-0 py-2.5 px-0 bg-transparent text-white appearance-none dark:text-white dark:placeholder-white dark:border-solid focus:outline-none focus:ring-0 focus:border-gray-200;
  }

  .sales-force-form .form__field::placeholder {
    @apply text-transparent;
  }

  .sales-force-form .form__field:placeholder-shown~.form__label {
    @apply text-base cursor-text top-5 left-1 text-stone-400;
  }

  .sales-force-form label {
    @apply font-Forza text-base font-normal not-italic tracking-[0.7px];
  }

  .sales-force-form label,
  .sales-force-form .form__field:focus~.form__label {
    @apply absolute top-0 text-left block [transition:0.2s] text-xs text-stone-400;
  }

  .sales-force-form label.error {
    @apply absolute top-[-10px] text-left block [transition:0.2s] text-xs text-primary;
  }

    .sales-force-form .form__field_error {
        @apply absolute top-[-10px] text-left block [transition:0.2s] text-xs;
    }

  .hp-field {
    display: none !important;
  }

    .grecaptcha-badge {
        visibility: hidden;
    }

}

/* SalesForce Form Style End*/
/* Custom Checkbox */
@layer components {

  input[type="checkbox"] {
    font: inherit;
    height: 1.15em;
    width: 1.15em;
    transform: translateY(-0.075em);
    @apply appearance-none bg-inherit inline-grid place-content-center focus:ring-2 focus:ring-[#2c5de5]/30 text-[var(--input-border-active)] rounded-[var(--radius-md)] border border-[color:var(--input-border-default)] hover:border-[color:var(--input-border-active)]
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #1111111a;
  }
  input[type="checkbox"]:hover::before{
    transform: scale(1);
  }
}