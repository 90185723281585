﻿/* video intro section */

.slide-up-hero {
    background: var(--color-neutral-1000);
}

.hero-position {
    height: 0;
    top: 0;
    left: 0;
    right: 0;
    position: sticky;
    z-index: 1;
}

.hero-wrapper {
    width: 100%;
    height: 100vh;
    position: absolute;
}

.hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    opacity: .8;
}

.embed-media{
  aspect-ratio:var(--embed-aspect) auto;
  iframe{
    width: 100%;
    height: 100%;
  }
}

// .logo {
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 15vh 0;
// }


.hero-content {
    background-color: rgba(34,34,34,0);
    z-index: 5;
    position: relative;
    text-align: center;
}


.hero-heading {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 15vh 0;
}




.hero-statement {
    margin: 0 auto;
    max-width: 55ch;
    padding: 30vh var(--container-padding) 50vh var(--container-padding);
    text-align: center;
}
