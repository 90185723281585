﻿.vertical-gallery-snap {
  scroll-snap-type: y mandatory;
 }
 
 
 .vertical-carousel {
   position: relative;
   z-index: 1;
   background-color: var(--color-neutral-1000);
   display: flex;
   flex-direction: row-reverse;
 }
 
 .vertical-carousel-nav-position {
   width: 0;
   height: 100vh;
   position: sticky;
   top: 0; 
    z-index: 3;
   
 }
 
 .vertical-carousel-nav {
   position: absolute;
   top: 50%;
   right: var(--container-padding);
   transform: translateY(-50%);
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: .5rem;
   
 }
 
 .vertical-carousel-nav-item {
   background: white;
   border-radius: var(--radius-md);
   height: 1.5rem;
   width: 6px;
   opacity: .5;
   transition: all var(--transition-timing-md) var(--ease-in-out);
   
   &:hover {
      opacity: 1;
   }
   
   &.active {
     opacity: 1;
     height: 3rem;
   }
 }
 
//  .vertical-carousel-nav {}
 
 .vertical-carousel-items {
   width: 100%;
    
   
 }
 
 .vertical-carousel-item {
   height: 100vh; // fallback if browser doesn't support svh
   height: 100svh;
  //  height: 100dvh; /* Use  */
   width: 100%;
   position: relative;
   scroll-snap-align: start;
   
 
  //  video {
  //    opacity: 0.8;
  //  }
 }
 
 .vertical-carousel-text {
   width: 100%;
  //  height: 100%;
   height: calc(100% - 1rem); // 100% - 1rem height of container
   position: absolute;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-start;
   z-index: 2;
   //padding: calc(var(--container-padding-lg) * 2) var(--container-padding-lg);
   padding: var(--container-padding-lg);
   .badge {
     max-width: 100px;
     aspect-ratio: 100/107;
   }
 }
 
 .vertical-carousel-item-sticky {
   height: 0;
   position: sticky;
   top: 0;
   left: 0;
   right: 0;
 }
 
 .vertical-carousel-item-spacer {
   height: 100vh; // fallback if browser doesn't support svh
   height: 100svh; 
  //  height: 100dvh;
   width: 100%;
   position: absolute;
   top: 0;
   left: 0;
 }
 
 .vertical-carousel-asset {
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: absolute;
 }
 