﻿ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.navigation {
    // local variables
    --navigation-width: min(25vw,calc(304px + (2 * var(--container-padding))));
    --navigation-width-boats: calc(90vw - (var(--navigation-width) * 2));
    --navigation-padding-vertical: 5.5rem;

    @media (min-width: $screen-lg) {
        --navigation-width-boats: 640px;
    }

    display: none;
    position: absolute;
    z-index: 15;
    left: 0;
    top: 0;
    min-height: 100vh;
    width: 100%;
    transform: translateX(-100%);
    opacity: 0;
    transition: all var(--transition-timing-md) var(--ease-in-out);
    color: var(--color-neutral-800);

    @media (min-width: $screen-md) {
        width: var(--navigation-width);
        transition-delay: calc( var(--transition-timing-xl) - var(--transition-timing-md) );
    }

    .nav-active & {
        display: block;
    }

    .show-nav & {
        transform: translateX(0);
        opacity: 1;
        transition-delay: 0ms;
    }
}

.nav-list {
    list-style-type: none;
    padding: var(--navigation-padding-vertical) 0;
    margin: 0;
    width: 100%;
    background-color: var(--color-neutral-white);
    min-height: 100vh;

    @media (min-width: $screen-md) {
        padding-left: var(--container-padding);
        padding-right: var(--container-padding);
    }
}

.nav-link {
    font-size: 2rem;
    color: var(--color-neutral-800);
    border-radius: var(--radius-md);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    transition: background-color var(--transition-timing-sm) var(--ease-out), color var(--transition-timing-sm) var(--ease-out);

    &:hover,
    &:active {
        color: var(--color-neutral-800);
        text-decoration: none;
    }

    @media (min-width: $screen-md) {
        color: var(--color-neutral-500);

        .boats-active &,
        .active & {
            background-color: var(--color-neutral-100);
            color: var(--color-neutral-800);
        }
    }
}

.nav-icon-chevron {
    width: 20px;
    height: 20px;
    transition: all var(--transition-timing-sm) var(--ease-out);

    .nav-link:hover & {
        transform: translateX(6px);
    }
}

// does nothing except at mobile
.secondary-list-container {
    @media (max-width: $screen-sm-max) {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 11;
        background: var(--color-neutral-100);
        overflow-y: auto;
        transform: translateX(100%);
        opacity: 0;
        transition: all var(--transition-timing-md) var(--ease-in-out);

        .active & {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @media (min-width: $screen-md) {
    }
}

.back-item {
    // hide for desktop users
    @media (min-width: $screen-md) {
        display: none;
    }
}

.back-button {
    color: var(--color-neutral-800);
}

.nav-list-secondary,
.nav-list-tertiary {
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);

    @media (min-width: $screen-md) {
        width: var(--navigation-width);
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateX(-100%);
    }

    li {
        margin-bottom: 1.5rem;
    }

    ul {
        margin-bottom: 4rem;
    }

    h5 {
        margin-bottom: 1.5rem;
        color: inherit;
    }
}

.nav-list-secondary {
    background: var(--color-neutral-100);
    padding-top: var(--navigation-padding-vertical);
    padding-bottom: 3.5rem;
    color: var(--color-neutral-800);

    @media (min-width: $screen-md) {
        z-index: -1;
        padding-bottom: var(--navigation-padding-vertical);
        transition: all var(--transition-timing-lg) var(--ease-in-out);
        transition-delay: calc( var(--transition-timing-xl) - var(--transition-timing-lg) );
        overflow-y: auto;

        .boats-active &,
        .active & {
            transform: translateX(var(--navigation-width));
            transition-delay: 0ms;
        }
    }

    a {
        color: inherit;
    }
}

.nav-list-boats {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media (min-width: $screen-md) {
        width: var(--navigation-width-boats);
    }
}

.no-vertical-scrollbar::-webkit-scrollbar{
 display: none;
}

.no-vertical-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.nav-list-tertiary {
    background: var(--color-neutral-800);
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;

    @media (min-width: $screen-md) {
        z-index: -2;
        color: var(--color-neutral-100);
        transition: all var(--transition-timing-xl) var(--ease-in-out);
        transition-delay: 0ms;
        padding-top: var(--navigation-padding-vertical);
        padding-bottom: var(--navigation-padding-vertical);

        .boats-active &,
        .active & {
            transform: translateX( calc(var(--navigation-width) + var(--navigation-width)) );
        }
        // different transform for boats since it's wider
        .nav-list-item-boats.active &,
        .boats-active & {
            transform: translateX( calc(var(--navigation-width) + var(--navigation-width-boats)) );
        }
    }

    a {
        color: var(--color-neutral-white);
    }
}

.boat-item {
    border-radius: var(--radius-md);
    background-color: var(--color-neutral-200);
    padding: 2rem;
    transition: background-color var(--transition-timing-sm) ease-out;

    &:hover {
        background-color: var(--color-neutral-300);
        cursor: pointer;

        .family-name {
           text-decoration: underline;
        }

        .right-arrow{
            display: block;
        }
    }
}

.overlay {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 12;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: backdrop-filter var(--transition-timing-md) var(--ease-out), opacity var(--transition-timing-md) var(--ease-out);
    // Toggle display of the overlay same as navigation
    .nav-active & {
        display: block;
    }
    // animation of the overlay
    .show-nav & {
        backdrop-filter: blur(2px);
        opacity: 1;
    }
}
