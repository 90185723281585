﻿.question-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}


.multiple-question-choice-card-container, .single-question-choice-card-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    gap: 1rem;


    @media (min-width: $screen-md) {
        flex-direction: row;
    }
}

.single-choice-button-card-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;

    @media (min-width: $screen-md) {
        flex-direction: row;
        padding: 1rem 0 0 0;
    }
}

.question-single-slider-card-container, .question-dual-slider-card-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem;

    @media (min-width: $screen-md) {
        padding: 1rem 0 0 0;
    }
}

.question-choice-card, .question-choice-single-card {
    position: relative;
    border: 5px solid transparent;


    &.selected {
        border: 5px solid #e5e7eb;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;

        &:after {
            content: '\f00c';
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            //content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='100' cy='50' r='40' stroke='black' stroke-width='2' fill='red'/%3E%3Cpolyline points='20,20 40,25 60,40 80,120 120,140 200,180' style='fill:none;stroke:black;stroke-width:3'/%3E%3C/svg%3E ");
            position: absolute;
            right: 1rem;
            top: 1rem;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            font-size: 1.5rem;
            text-align: center;
            border: 1px solid #aaa;
            background: #f8f8f8;
            border-radius: 50%;
            box-shadow: inset 0 1px 3px rgba(0,0,0,.3)
        }
    }
}

.question-card-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
}

.question-choice-card-text, .question-choice-single-card-text {
    position: absolute;
    font-family: 'Tungsten';
    font-size: 3rem;
    color: white;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    text-transform: uppercase;
}
