﻿@mixin heading() {
  font-family: var(--text-heading);
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.05em;
}

@mixin subheading() {
  font-family: var(--text-subheading);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 0.2em;
}

@mixin stats-number() {
  font-family: var(--text-subheading, "Forza");
  font-weight: 500;
  line-height: 1.1;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin display-tracked() {
  font-family: var(--text-heading);
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.3em;
}

.heading {
  @include heading;
}

.subheading {
  @include subheading;

  &-sm {
    @include subheading;
    font-size: 0.625rem;

    @media (min-width: $screen-md) {
      font-size: 0.75rem;
    }
  }

  &-md {
    @include subheading;
    font-size: 0.75rem;

    @media (min-width: $screen-md) {
      font-size: 0.875rem;
    }
  }

  &-lg {
    @include subheading;
    font-size: 1rem;
  }
}

p {
  line-height: 1.75em;
  color: var(--text-primary);
  font-size: 0.875rem;

  .theme-light & .theme-dark & .theme-gray & {
    background: var(--background-primary);
    color: var(--text-primary);
  }

  @media (min-width: $screen-md) {
    font-size: 1rem;
  }

  &.sm {
    font-size: 0.75rem;

    @media (min-width: $screen-md) {
      font-size: 0.875rem;
    }
  }

  &.lg {
    font-size: 1rem;

    @media (min-width: $screen-md) {
      font-size: 1.125rem;
    }
  }

  &.xl {
    font-size: 1.125rem;

    @media (min-width: $screen-md) {
      font-size: 1.5rem;
    }
  }

  &.display {
    font-size: clamp(1.25rem, 2vw, 2.5rem);
  }
}

.rte-para {
  line-height: 1.75em;
  color: var(--text-primary);
  font-size: 0.875rem;

  @media (min-width: $screen-md) {
    font-size: 1rem;
  }

  &-sm {
    @extend .rte-para;
    font-size: 0.75rem;

    @media (min-width: $screen-md) {
      font-size: 0.875rem;
    }
  }

  &-lg {
    @extend .rte-para;
    font-size: 1rem;

    @media (min-width: $screen-md) {
      font-size: 1.125rem;
    }
  }

  &-xl {
    @extend .rte-para;
    font-size: 1.125rem;

    @media (min-width: $screen-md) {
      font-size: 1.5rem;
    }
  }

  &-display {
    @extend .rte-para;
    font-size: clamp(1.25rem, 2vw, 2.5rem);
  }
}

a {
  text-decoration: none;
  color: var(--text-primary);
}

a:hover {
  text-decoration: underline;
}

h1,
.heading-1,
h2,
.heading-2,
h3,
.heading-3,
h3,
.heading-3,
h4,
.heading-4,
h5,
.heading-5,
h6,
.heading-6,
.display-1,
.display-2,
.display-3,
.display-tracked {
  @include heading;
  margin: 0;
  color: var(--text-primary);

  .theme-light & .theme-dark & .theme-gray & {
    background: var(--background-primary);
    color: var(--text-primary);
  }
}

h1,
.heading-1 {
  font-size: 3.5rem;

  @media (min-width: $screen-md) {
    font-size: 6rem;
  }
}

h2,
.heading-2 {
  font-size: 1.8rem;

  @media (min-width: $screen-md) {
    font-size: 4.5rem;
  }
}

h3,
.heading-3 {
  font-size: 2rem;

  @media (min-width: $screen-md) {
    font-size: 3.5rem;
  }
}

h4,
.heading-4 {
  font-size: 1.5rem;

  @media (min-width: $screen-md) {
    font-size: 2rem;
  }
}

h5,
.heading-5 {
  font-size: 1.25rem;

  @media (min-width: $screen-md) {
    font-size: 1.5rem;
  }
}

h6,
.heading-6 {
  font-size: 1.125rem;
}

.display-1 {
  font-size: clamp(5.5rem, 12vw, 16rem);
}

.display-2 {
  font-size: clamp(4.5rem, 7.14vw, 12rem);
}

.display-3 {
  font-size: clamp(3.5rem, 5.7vw, 8rem);
}

.display-tracked {
  @include display-tracked;
}

.display-tracked-1 {
  @include display-tracked;
  font-size: clamp(2.5rem, 4.7vw, 4rem);
}

.display-tracked-2 {
  @include display-tracked;
  font-size: clamp(1.125rem, 1.9vw, 2.25rem);
}

.stats-number {
  &-sm {
    @include stats-number;
    font-size: 0.75rem;
    letter-spacing: 0.2em;

    @media (min-width: $screen-md) {
      font-size: 0.875rem;
    }
  }

  &-md {
    @include stats-number;
    font-size: 1.5rem;
  }

  &-lg {
    @include stats-number;
    font-size: 1.5rem;

    @media (min-width: $screen-md) {
      font-size: 3rem;
    }
  }
}

.text-link {
  position: relative;
  display: inline-flex;
  gap: 0.5rem;
  @include heading;
  color: var(--text-link);
  font-size: 1.125rem;
  font-weight: 375;
  line-height: 1.2;
  letter-spacing: 0.1em;

  &[data-size="sm"] {
    font-size: 1rem;
    gap: 0.375rem;
  }

  &[data-size="md"] {
    font-size: 1.125rem;
    gap: 0.5rem;
  }

  &[data-size="lg"] {
    font-size: 1.5rem;
    gap: 0.75rem;
  }


  &::after {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: var(--text-link);
    transform-origin: 100%;
  }

  &:hover {
    color: var(--text-link-hover);
    text-decoration: none;
    cursor: pointer;

    &::after {
      animation: text-link-hover 500ms ease-in-out 1;

      &.no-hover-animation {
        animation: none;
      }
    }

    &[disabled] {
      color: var(--button-text-tertiary-disabled);
      cursor: none;
      pointer-events: none;
      animation: none;
    }
  }

  &.no-underline {
    &::after {
      transform: scaleX(0);
      transform-origin: 0;
    }

    &:hover {
      color: var(--text-link-hover);

      &::after {
        transform: scaleX(1);
        animation: no-underline-hover var(800ms) ease-in-out 1;

        &.no-hover-animation {
          animation: none;
        }
      }
    }

    &[disabled] {
      color: var(--button-text-tertiary-disabled);
      pointer-events: none;
      animation: none;
    }
  }
}

@keyframes text-link-hover {
  0% {
    transform: scaleX(1);
    transform-origin: 100%;
  }

  50% {
    transform: scaleX(0);
    transform-origin: 100%;
  }

  51% {
    transform: scaleX(0);
    transform-origin: 0;
  }

  100% {
    transform: scaleX(1);
    transform-origin: 0;
  }
}

@keyframes no-underline-hover {
  0% {
    transform: scaleX(0);
    transform-origin: 0;
  }

  33% {
    transform: scaleX(1);
    transform-origin: 0;
  }

  34% {
    transform: scaleX(1);
    transform-origin: 100%;
  }

  66% {
    transform: scaleX(0);
    transform-origin: 100%;
  }

  67% {
    transform: scaleX(0);
    transform-origin: 0;
  }

  100% {
    transform: scaleX(1);
    transform-origin: 0;
  }
}