@font-face {
    font-family: 'Tungsten';
    src: url('./Tungsten-Semibold.woff2') format('woff2'), url('./Tungsten-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Tungsten';
    src: url('./Tungsten-Medium.woff2') format('woff2'), url('./Tungsten-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

