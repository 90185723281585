[data-spec-name="dimensions"]{
  counter-reset: spec;


  & > li >div:first-child::before{
    counter-increment: spec;
    content:counter(spec,upper-alpha);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #222;
    background-color: white;
    padding: .125rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    border-radius: 50%;
    text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Tungsten;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 1rem */


  }
}

