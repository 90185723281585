.hero-banner {
  height: 100vh;
  z-index: 1;
  background: var(--color-neutral-1000);
  
  & + .page-content {
    background: var(--background-primary);
    padding-top: var(--stacked-component-lg);
  }
}

.hero-banner-content {
  position: relative;
  z-index: 2;
  padding: var(--stacked-component-md) var(--container-padding-lg);
  height: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  gap: 1rem;
  
  & * {
    margin: 0;
  }
  
  &.left-justified {
    text-align: left;
    align-items: flex-start;
  }

  .center {
    justify-content: flex-end;
    text-align: center;
  }
  
  p {
    max-width: 50ch;
    &.xl {
      font-size: clamp(1.125rem, 1.5vw, 1.5rem);
    }
  }
}

.hero-banner-image {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .8;

}