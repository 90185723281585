﻿.dab-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: var(--color-neutral-900) url(../../assets/images/dab-bg.jpg) no-repeat fixed 50% 50%;
    background-size: cover;
    gap: 40px;
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    padding-top: var(--stacked-component-lg);
    overflow: hidden;

    @media (min-width: $screen-md) {
        align-items: center;
    }
}

.dab-cta-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dab-cta-text {
    max-width: var(--container-width-md);
}

.dab-cta-image {
    aspect-ratio: 2400/720;
    width: 960px;
    max-width: 960px;

    @media (min-width: $screen-md) {
        width: auto;
        max-width: 100%;
    }
}
