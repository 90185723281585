﻿$color_1: transparent;
$background-color_1: #2698D9;
$background-color_2: #EDEDED;
$border-color_1: transparent;

/* All the same stuff for Firefox */
/* All the same stuff for IE */
/*.wrapper {
    position: absolute;
    width: 400px;
    height: 200px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}*/

.multi-range-slider {
    position: relative;
    width: 80%;
    height: 20px;
    display: block;
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    display: block;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 10;
    height: 100%;
    margin: 0;
    pointer-events: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        pointer-events: all;
        -webkit-appearance: none;
        cursor: pointer;
        border: 0 none;
        content: '\f053';
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        width: 15px;
        height: 15px;
        background-color: #000;
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(38, 152, 217, 0.1);
        transition: box-shadow 0.3s ease-in-out;

        &:before {
            content: '+';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: red;
        }
    }

    &:focus {
        outline: none;

        &::-webkit-slider-runnable-track {
            background: transparent;
        }

        &::-ms-fill-lower {
            background: transparent;
        }

        &::-ms-fill-upper {
            background: transparent;
        }
    }

    &::-ms-track {
        width: 100%;
        cursor: pointer;
        content: '\f053';
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        background: transparent;
        border-color: $border-color_1;
        color: #000;
        background: transparent;

        &:before {
            content: '\f053';
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: red;
        }
    }

    &::-moz-range-thumb {
        pointer-events: all;
        cursor: pointer;
        border: 0 none;
        width: 15px;
        height: 15px;
        background-color: #000;
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(38, 152, 217, 0.1);
        transition: box-shadow 0.3s ease-in-out;

        &:before {
            content: '\f053';
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: red;
        }
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &::-ms-thumb {
        pointer-events: all;
        border: 0 none;
        cursor: pointer;
        width: 15px;
        height: 15px;
        background-color: #000;
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(38, 152, 217, 0.1);
        transition: box-shadow 0.3s ease-in-out;

        &:before {
            content: '\f053';
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: red;
        }
    }

    &::-webkit-slider-runnable-track {
        background: transparent;
    }

    &::-moz-range-track {
        background: transparent;
    }

    &::-ms-fill-lower {
        background: transparent;
    }

    &::-ms-fill-upper {
        background: transparent;
    }
}

input[type=range].hover {
    &::-webkit-slider-thumb {
        box-shadow: 0 0 0 10px rgba(38, 152, 217, 0.1);
    }

    &::-moz-range-thumb {
        box-shadow: 0 0 0 10px rgba(38, 152, 217, 0.1);
    }

    &::-ms-thumb {
        box-shadow: 0 0 0 10px rgba(38, 152, 217, 0.1);
    }
}

input[type=range].active {
    &::-webkit-slider-thumb {
        box-shadow: 0 0 0 20px rgba(38, 152, 217, 0.2);
    }

    &::-moz-range-thumb {
        box-shadow: 0 0 0 20px rgba(38, 152, 217, 0.2);
    }

    &::-ms-thumb {
        box-shadow: 0 0 0 20px rgba(38, 152, 217, 0.2);
    }
}

.slider {
    position: absolute;
    width: 100%;
    top: 7px;
    z-index: 1;
    height: 6px;

    > .track {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: #E0DFDC;
    }

    > .range {
        position: absolute;
        z-index: 2;
        left: 25%;
        right: 25%;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: #000;
    }
}

.price__wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-top: 20px;
    width: 80%;
}
