@font-face {
    font-family: 'Font Awesome 6 Free';
    src: url('./fa-regular-400.woff2') format('woff2');
    font-weight:900;
}
@font-face {
    font-family: 'Font Awesome 6 Free';
    src: url('./fa-solid-900.woff2') format('woff2');
    font-weight: 900;
}

@font-face {
    font-family: 'Font Awesome 6 Brands';
    src: url('./fa-brands-400.woff2') format('woff2');
}

