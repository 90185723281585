﻿/* Breakpoints
  ----------------------------------------------------------*/
$screen-sm: 480px;
$screen-md: 800px;
$screen-lg: 1280px;
$screen-xl: 1440px;

$screen-xs-max: $screen-sm - 1;
$screen-sm-max: $screen-md - 1;
$screen-md-max: $screen-lg - 1;
$screen-lg-max: $screen-xl - 1;



:root {
    --text-heading: Tungsten;
    --text-subheading: Forza;
    --text-content: "Acumin Pro", sans-serif;
    /* Primitive Color Variables
  ----------------------------------------------------------*/
    --color-neutral-white: #fff;
    --color-neutral-100: #f4f4f3;
    --color-neutral-200: #e9e8e6;
    --color-neutral-300: #e0dfdc;
    --color-neutral-400: #969692;
    --color-neutral-450: #7D7D79;
    --color-neutral-500: #71716d;
    --color-neutral-550: #696964;
    --color-neutral-600: #52524f;
    --color-neutral-700: #373736;
    --color-neutral-800: #232321;
    --color-neutral-900: #111;
    --color-neutral-1000: #000;
    --color-primary-100: #D12421;
    --color-primary-200: #B32926;
    --color-primary-300: #990A07;
    --color-secondary-light-gray: #E5E7E8;
    --color-secondary-gray: #757B79;
    --color-secondary-dark-gray: #50535E;
    --color-secondary-beige: #B4AFA3;
    --color-secondary-red: #823C38;
    --color-secondary-dark-red: #443131;
    --color-success-100: #D6F3E2;
    --color-success-200: #0B9061;
    --color-success-300: #008556;
    --color-success-400: #086343;
    --color-warning-100: #FFE1BE;
    --color-warning-200: #E86825;
    --color-warning-300: #CD4D0A;
    --color-warning-400: #A24D20;
    --color-error-100: #FFD6D7;
    --color-error-200: #EE2C32;
    --color-error-300: #DE1C22;
    --color-error-400: #9F1B1F;
    --gradient-transparent-to-100: linear-gradient( 180deg, rgba(244, 244, 243, 0) 0%, rgba(244, 244, 243, 1) 100% );
    --gradient-dark: linear-gradient(180deg, var(--color-neutral-800) 0%, #191919 100%);
    --overlay-light: rgba(0,0,0,.3);
    --overlay-dark: rgba(0,0,0,.8);
    // Border Radius
    --radius-sm: 1px;
    --radius-md: 3px;
    --radius-lg: 6px;
    // Animation and Timing Variables
    --ease-in-out: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    --ease-out: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --transition-timing-sm: 250ms;
    --transition-timing-md: 400ms;
    --transition-timing-lg: 600ms;
    --transition-timing-xl: 800ms;
    --transition-timing-xxl: 1000ms;
    /* Layout and Component Spacing
  ----------------------------------------------------------*/
    --stacked-component-sm: 48px;
    --stacked-component-md: 64px;
    --stacked-component-lg: 80px;

    @media (min-width: 800px) {
        --stacked-component-sm: 64px;
        --stacked-component-md: 96px;
        --stacked-component-lg: 112px;
    }

    @media (min-width: 1280px) {
        --stacked-component-sm: 80px;
        --stacked-component-md: 112px;
        --stacked-component-lg: 144px;
    }
    // Note:  these values are FPO until the project is more defined.
    --grid-vertical-spacing: 24px;
    --grid-horizontal-spacing: 24px;

    @media (min-width: 800px) {
        --grid-vertical-spacing: 32px;
        --grid-horizontal-spacing: 24px;
    }

    @media (min-width: 1280px) {
        --grid-vertical-spacing: 40px;
        --grid-horizontal-spacing: 24px;
    }

    --container-width-sm: 420px;
    --container-width-md: 62ch; // this is for content parts of the site where we can base a container width on a legible amount of characters
    --container-width-lg: 1280px;
    --container-width-xl: 1440px;
    --container-padding: 1.5rem;

    @media (min-width: 480px) {
        --container-padding: 2rem;
    }

    @media (min-width: 800px) {
        --container-padding: 2.8vw; // viewport width for larger desktops
    }

    --container-padding-lg: 2rem;

    @media (min-width: 480px) {
        --container-padding-lg: 3rem;
    }

    @media (min-width: 800px) {
        --container-padding-lg: 7.14vw; // viewport width for larger desktops
    }
    /* Element Padding and Spacing Variables
  ----------------------------------------------------------*/
    --button-padding-vertical-sm: 10px;
    --button-padding-vertical-md: 12px; // default size
    --button-padding-vertical-lg: 16px;
    --button-padding-horizontal-sm: 18px;
    --button-padding-horizontal-md: 24px; // default size
    --button-padding-horizontal-lg: 28px;
    --button-gap-sm: 6px;
    --button-gap-md: 8px; // default size
    --button-gap-lg: 12px;
    --input-padding-vertical: 14px;
    --input-padding-horizontal: 16px; // default size
    --input-gap: 10px;
    --tooltip-padding-vertical: 5px;
    --tooltip-padding-horizontal: 8px;
}
