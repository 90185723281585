﻿button,
a.button,
span.button {
    border: 1px solid transparent;
    border-radius: var(--radius-md);
    color: var(--color-neutral-white);
    display: inline-flex;
    justify-content: center;
    gap: 0.5rem;
    @include heading;
    font-size: 1rem;
    font-weight: 375;
    line-height: 1.2;
    letter-spacing: 0.1em;
    padding-inline: 1.5em;
    padding-block: 0.75em;

    @media (max-width: $screen-xs-max) {
        padding-inline: var(--button-padding-horizontal-sm, 1.125rem);
        padding-block: var(--button-padding-vartical-sm, 0.625rem);
    }

    @media (min-width: $screen-sm) {
        padding-inline: var(--button-padding-horizontal-md, 1.125rem);
        padding-block: var(--button-padding-vartical-md, 0.625rem);
    }

    &.fixed-width {
        width: clamp(auto,8rem + 5vw,12.5rem)
    }



    &[data-size="btn-sm"] {
        line-height: 1.25;
        gap: 0.375rem;
        padding-inline: var(--button-padding-horizontal-sm, 1.125rem);
        padding-block: var(--button-padding-vartical-sm, 0.625rem);
    }

    &[data-size="btn-md"] {
        font-size: 1.125rem;
        gap: 0.5rem;
        padding-inline: var(--button-padding-horizontal-md, 1.5rem);
        padding-block: var(--button-padding-vartical-md, 0.75rem);
    }

    &[data-size="btn-lg"] {
        font-size: 1.5rem;
        gap: 0.75rem;
        letter-spacing: 0.05em;
        padding-inline: var(--button-padding-horizontal-lg, 1.75rem);
        padding-block: var(--button-padding-vartical-lg, 1rem);
    }

    &.primary {
        background-color: var(--button-background-primary);

        &:hover {
            background-color: var(--button-background-primary-hover);
        }

        &.active {
            background-color: var(--button-background-primary-hover);
        }

        &[disabled] {
            color: var(--button-text-disabled);
            background-color: var(--button-background-disabled);
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    &.secondary {
        background-color: var(--button-background-secondary);
        color: var(--button-text-secondary);

        &:hover {
            background-color: var(--button-background-secondary-hover);
        }

        &.active {
            background-color: var(--button-background-secondary-hover);
        }

        &[disabled] {
            color: var(--button-text-disabled);
            background-color: var(--button-background-disabled);
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    &.tertiary {
        border-color: var(--button-border-tertiary);
        color: var(--button-text-tertiary);
        background: transparent;

        &:hover {
            color: var(--button-text-secondary);
            background-color: var(--button-background-secondary);
        }

        &.active {
            color: var(--button-text-secondary);
            background-color: var(--button-background-secondary);
        }

        &[disabled] {
            color: var(--button-text-tertiary-disabled);
            background-color: var(--button-background-tertiary-disabled);
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.button-row {
  display: flex;
  gap: .75rem;
}


.chicklet{
  font-family: var(--text-content,"Acumin Pro");
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: var(--radius-md);
  color:var(--button-text-chicklet);
  background: var(--button-background-chicklet);
  padding-inline:0.5rem ;
  padding-block:0.5rem;
  
  &[data-size="chicklet-sm"]{
    padding:0.5rem;
  }

  &[data-size="chicklet-lg"]{
    padding-inline: 0.875rem;
    padding-block: 0.75rem;
  }

  &:hover{
    text-decoration: none;
  }

  &.is-link{
    &:hover{
      color: var(--button-text-chicklet-hover);
      background: var(--button-background-chicklet-active);
      cursor:pointer;
    }
  }
}