﻿$container-gap: 1.5rem;
.vertical-features-container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @media (min-width: $screen-md) {
        flex-direction: row;
        gap: 5rem;
        align-items: flex-start;
    }

    .blurb {

        @media (min-width: $screen-md) {
            flex-grow: 1;
            position: sticky;
            top: var(--masthead-height-combined);
            left: 0;
            padding-top: 1rem;
        }
    }
}

.vertical-feature-items {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: $container-gap;
    margin-left: calc(var(--container-padding) * -1);
    margin-right: calc(var(--container-padding) * -1);
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    padding-bottom: 2.5rem;

    @media (min-width: $screen-md) {
        max-width: 800px;
        flex-direction: column;
        margin: 0;
        padding: 0;
        gap: 2.5rem;
    }

    img {
        aspect-ratio: 1/1;
        margin-bottom: 1rem;

        @media (min-width: $screen-md) {
            aspect-ratio: 8/5;
        }
    }

    .feature-item {
        @media (min-width: $screen-md) {
            flex-shrink: 0;
            width: auto;
            max-width: 100%;
        }
    }
}
