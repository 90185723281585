

/**umb_name:Header 1*/
h1.rte-header-1 {
	font-weight:bold;
	font-size:37px;
	letter-spacing:1.5px;
}

/**umb_name:Marquee-Text*/
.marquee-text {
	position: absolute;
	z-index: 11;
/*	font-size: 12vw;*/
	white-space: nowrap;
	top: 10vw;
	font-weight: 600;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	text-transform: uppercase;
	line-height: 1em;
	letter-spacing: 0.05em;
	font-family: "Tungsten";
}

/**umb_name:Marquee-Text-Small*/
.marquee-text-small {
	position: absolute;
	z-index: 11;
	white-space: nowrap;
	left: 0;
	right: 0;
	text-align: center;
	font-weight: 600;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	text-transform: uppercase;
	line-height: 1em;
	letter-spacing: 0.3em;
	font-family: "Tungsten";
}

/**umb_name:Marquee-Text-Light*/
.marquee-text-light {
	opacity: .3;
}
