

/**umb_name:Thin(100)*/
.rte-font-thin {
	font-weight: 100;
}

/**umb_name:Light(300)*/
.rte-font-light {
	font-weight: 300;
}

/**umb_name:Normal(400)*/
rte-font-normal {
	font-weight: 400;
}

/**umb_name:Semi-Bold(600)*/
.rte-font-semibold {
	font-weight: 600;
}

/**umb_name:Bold(700)*/
.rte-font-bold {
	font-weight: 700;
}

/**umb_name:Extra-Bold(900)*/
.rte-font-bold-xl {
	font-weight: 900;
}
