[data-alias-name="boatFamilyCarouselRow"] {
    [id*="primary"] .splide__track {
        overflow: unset;
        overflow-x: clip;
    }

    [id*="secondary"] {
        .splide__arrow {
            top: calc(97px /2);
            background: none;
        }
    }

    [id*="secondary"].theme-dark {
        .splide__arrow svg {
            fill: #fff;
        }
    }
}

[data-alias-name="boatFamilyHeroSliderRow"] {
    [id*="secondary"] {
        .splide__track--nav > .splide__list > .splide__slide {
            padding: 1rem;

            &.is-active {
                background-color: rgba(0, 0, 0, 5%);
                border: none;
            }
        }
    }
}


.elementToFadeInAndOut {
  --animation-time: 2s;

  -webkit-animation: fadeinout var(--animation-time) linear forwards;
  animation: fadeinout var(--animation-time) linear forwards;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}