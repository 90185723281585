﻿/* interactive Marquee */

.marquee-section {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.marquee-text{
    position: absolute;
    z-index: 11;
    font-size: 12vw;
    white-space: nowrap;
    top: 10vw;
    @include heading;
}

.marquee-foreground,
.marquee-background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 10;
}

.marquee-foreground {
    z-index: 12;
}

.marquee-text-light {
    opacity: .3;
}

.marquee-text-small {
    position: absolute;
    z-index: 11;
    font-size: 4vw;
    white-space: nowrap;
    top: 10vw;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: lighter;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 0.3em;
    font-family: "Tungsten";

    @media (max-width: $screen-sm-max) {
       font-size:2rem;
       top:20vw;
    }
}
