.stickyBox {
  position: sticky;
  top: 0;
  background: white;
  padding-bottom: 1.5rem;

  @media (max-width: $screen-sm-max) {
    padding-top: 1.5rem;
  }
  nav:has(.masthead-wrapper .masthead-sticky) + main & {
    top:var(--sticky-masthead-height);
  }

  nav:has(.masthead-wrapper.slide-in)  + main & {
    top: var(--masthead-height);
    transition: top var(--transition-timing-sm) var(--ease-out) 300ms;
  }

  nav:has(.masthead-wrapper.slide-in :where(.masthead-sticky)) + main & {
    top: var(--masthead-height-combined);
    transition: top var(--transition-timing-sm) var(--ease-out) 300ms;
  }

  nav:has(.masthead-wrapper.slide-out)  + main & {
    transition: top var(--transition-timing-sm) var(--ease-out);
  }

  // &+section {
  //   padding-top: var(--sticky-box-height, 12rem)
  // }

  [data-boat-id] {
    aspect-ratio: auto;

    .relative {
      display: none;
    }
  }
}