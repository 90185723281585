@font-face {
    font-family: 'Forza';
    src: url('./Forza-Book.woff2') format('woff2'), url('./Forza-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Forza';
    src: url('./Forza-Book.woff2') format('woff2'), url('./Forza-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Forza';
    src: url('./Forza-Medium.woff2') format('woff2'), url('./Forza-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Forza';
    src: url('./Forza-Medium.woff2') format('woff2'), url('./Forza-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
