﻿.cta-row {
    background: var(--background-primary);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 9;
    gap: var(--grid-horizontal-spacing);

    @media (min-width: $screen-md) {
        padding-top: 3rem;
        padding-bottom: 3rem;
        flex-direction: row;
    }

    & + .cta-row {
        padding-top: 0;

        @media (min-width: $screen-md) {
            padding-top: 0;
        }
    }
}

.cta {
    box-sizing: border-box;
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    text-align: center;
    padding: 3.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: var(--radius-md);
    align-items: center;
    position: relative;
    overflow: hidden;
    max-height: calc(100vh - 1.5rem - var(--sticky-masthead-height));

    &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 2;
        background: linear-gradient( 0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100% );
    }

    &:hover {
        text-decoration: none;
    }

    @media (min-width: $screen-md) {
        padding: 5rem 2.5rem;
    }

    &.video {
        aspect-ratio: 9/16;

        @media (orientation: landscape) {
            aspect-ratio: 16/9;
        }
    }

    .heading-3,
    span:not(dialog span.absolute),/* exclude spans which are inside dialog with "absolute" class */
    p {
        position: relative;
        z-index: 3;
    }
}

.cta-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    overflow: hidden;
    z-index: 0;
}

.cta-video-content {
    z-index: 10;
    flex-direction: column;
    align-items: center;
    display: flex;
}

.cta-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    overflow: hidden;
    z-index: 0;
    transition: all var(--transition-timing-xl) var(--ease-out);

    .cta:hover & {
        transform: scale(1.04);
        opacity: 0.8;
    }
}
