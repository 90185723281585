﻿* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: var(--text-content);
  font-weight: 300;
  color: var(--text-primary);
  scroll-behavior: smooth;

  &.nav-active {
    overflow: hidden;
  }
}


.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-inline: var(--container-padding);

  &.no-padding {
    @media (max-width:480px) {
      padding-inline: 0;
    }
  }
}

.container-fluid {
  padding-inline: var(--container-padding, max(1.5rem, 2.5vw));

  &-lg {
    padding-inline: var(--container-padding-lg, max(2rem, 6.25vw));
  }
}

.container-sm,
.container-md,
.container-lg,
.container-xl {
  margin-left: auto;
  margin-right: auto;
}

.container-sm {
  max-width: calc(var(--container-width-sm) + (var(--container-padding) * 2));
}

.container-md {
  max-width: calc(var(--container-width-md) + (var(--container-padding) * 2));
}

.container-lg {
  max-width: calc(var(--container-width-lg) + (var(--container-padding) * 2));
}

.container-xl {
  max-width: calc(var(--container-width-xl) + (var(--container-padding) * 2));
}